:root {
  --background-color: #4e9599;
  --primary-color: #1cd2df;
  --secondary-color: #2e4261;
  --primary-font-color: #ffffff;
  --secondary-font-color: #2e4261;
  --beige: #e4d0b5;
}

#root {
  width: 100%;
}

#introduction {
  background-color: (var(--background-color));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2.5rem;
}

body {
  background-color: var(--background-color);
  margin: 0;
  font-family: 'Trebuchet MS', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-flow: row wrap;
  overflow-x: hidden;
  width: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.box-containerer {
  flex: 4;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.frontend-developer-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--primary-color);
  height: 40rem;
  width: 100%;
}

#about {
  color: var(--secondary-font-color);
  display: flex;
  flex-direction: column;
  width: 100%;
}

#about-description {
  margin: 0 auto;
  width: 90%;
}

#about-inner {
  background-color: var(--beige);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

#about-picture {
  height: 100%;
  width: 12rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

#contact {
  display: flex;
  flex-direction: column;
}

#contact-inner {
  background-color: var(--beige);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary-font-color);
}

.contact-link {
  color: var(--secondary-font-color);
  text-decoration: none;
  margin-bottom: 1rem;
}

#contact-links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;
}

#contact-svg {
  height: 10rem;
  width: 10rem;
  margin-left: 2rem;
}

#experience {
  background-color: var(--beige);
  color: var(--primary-font-color);
  display: flex;
  flex-direction: column;
}

.experience-description {
  font-size: 0.95rem;
}

.experience-description li {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#experience-inner {
  background-color: var(--secondary-color);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.experience-title {
  color: var(--primary-font-color);
  display: flex;
  text-decoration: none;
  white-space: nowrap;
}

.experience-section {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 90%;
}

.experience-years {
  font-size: .9rem;
  margin-bottom: .5rem;
}

#experience-outer-wrapper {
  background-color: var(--beige);
  margin-top: -0.1rem;
}

#full-name {
  font-size: 2.5rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  color: var(--primary-font-color);
}

#job-title {
  font-size: 1.5rem;
  margin-top: 0;
  color: var(--primary-font-color);
}

.menu-button {
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  background-color: transparent;
  color: var(--primary-font-color);
}

#mountains {
  margin-top: -0.2rem;
}

.page-breaks {
  margin-bottom: -0.6rem;
}

.portfolio-website-image {
  width: 100%;
  height: 12rem;
  margin-bottom: 2rem;
}

#projects {
  background-color: var(--secondary-color);
  color: var(--primary-font-color);
  display: flex;
  flex-direction: column;
}

#projects-inner {
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
}

.projects-video {
  margin-bottom: 2rem;
}

.projects-section {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 90%;
}

.projects-title {
  color: var(--primary-font-color);
  display: flex;
  text-decoration: none;
}

#projects-outer-wrapper {
  background-color: var(--secondary-color);
  margin-top: -0.1rem;
}

.section-header {
  font-size: 1.8rem;
  text-align: center;
}

.social-media-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  background: var(--secondary-color);
  padding: 0.5rem;
  text-align: center;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  margin: 0 1.3rem;
}

.social-media-buttons {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
}

button {
  border: none;
}

.frontend-developer-title {
  font-size: 3rem;
  margin: 0;
  padding-left: 6rem;
  line-height: 5rem;
}

/*CSS rules for small mobile screen sizes*/
@media only screen and (max-width: 320px) {
  .experience-title {
    white-space: normal;
  }

  .social-media-button {
    margin: 0 1rem;
  }
}

/*CSS rules for tablet screen sizes and up*/
@media only screen and (min-width: 768px) {
  #full-name {
    font-size: 4rem;
  }

  #job-title {
    font-size: 2rem;
  }

  .portfolio-website-image {
    height: 100%;
    width: 100%;
  }

  .projects-video {
    height: 20rem;
    width: 100%;
  }

  .social-media-button {
    margin: 0 3rem;
    transform: scale(1.5);
  }

  .social-media-buttons {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

/*CSS rules for laptop screen sizes and up*/
@media only screen and (min-width: 1024px) {
  #about {
    width: 55%;
  }

  #about-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--beige);
  }
  
  #introduction {
    width: 55%;
  }

  #introduction-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #experience {
    width: 55%;
  }

  #experience-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);
  }

  .menu-button {
    position: fixed;
    transform: scale(1.3);
  }

  #projects {
    width: 55%;
  }

  #projects-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
  }
}

/*CSS rules for large laptop screen sizes and up*/
@media only screen and (min-width: 1440px) {
  #introduction {
    width: 50%;
  }
}